import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ShellService } from './shell/shell.service';

const routes: Routes = [
  ShellService.childRoutes([
    {
      path: '',
      loadChildren: () =>
        import('./shell/shell.module').then((m) => m.ShellModule),
    },
  ]),
  AuthService.childRoutes([
    {
      path: 'auth',
      loadChildren: () =>
        import('./auth/auth.module').then((m) => m.AuthModule),
    },
  ]),
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
