import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface Options {
  method?: string;
  options?: any;
  body?: any;
  url: string;
}

@Injectable()
export class BaseService {
  httpOptions: any;

  constructor(public http: HttpClient) {
    this.http = http;
  }

  sendRequest<T>({ method, options, body, url }: Options) {
    if (options && Object.keys(options)) {
      Object.assign(this.httpOptions, options);
    }

    if (method == 'GET') {
      return this.http.get<T>(url, this.httpOptions);
    }
    return this.http.post<T>(url, body, this.httpOptions);
  }
}
