<div class="pagination">
    <button class="pagination__item">
      <i class="fas fa-angle-double-left"></i>
    </button>
    <button
      class="pagination__item"
      *ngFor="let el of [1]"
    >
      {{ el }}
    </button>
    <button class="pagination__item">
      <i class="fas fa-angle-double-right"></i>
    </button>
  </div>