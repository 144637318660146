import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponentsModule } from './page-components/page-components.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PageComponentsModule],
  exports: [PageComponentsModule],
})
export class SharedModule {}
