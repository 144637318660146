import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

const routes = {
  login: () => '',
  register: () => '',
  logout: () => '',
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService {
  messages = {
    required: 'This field is required',
  };

  constructor(private _http: HttpClient) {
    super(_http);
  }

  login(context: any) {
    let url = routes.login();

    return this.sendRequest<any>({ url, body: context });
  }

  register(context: any) {
    let url = routes.register();
    return this.sendRequest<any>({ url, body: context });
  }

  logout() {
    let url = routes.logout();
    this.sendRequest<any>({ url }).subscribe({
      next: (res) => {
        localStorage.clear();
      },
      error: () => {},
    });
  }
}
