import { Injectable } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: AuthComponent,
      children: routes,
    };
  }
}
