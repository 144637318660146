import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-em360',
  templateUrl: './em360.component.html',
  styleUrls: ['./em360.component.scss']
})
export class Em360Component implements OnInit {
  projectName = "Amplify 360"

  constructor() { }

  ngOnInit(): void {
  }

}
