let a:any = ["", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
let b:any = ["", "", 20, 30, 40, 50, 60, 70, 80, 90]
export function insWords(num,full) {
  if (!num) {
    return
  }
  if(typeof num !== "string"){
    num = num.toString()
  }

  let val = num.replace(/,/g, "")

  num = parseInt(val)
  if ((num = num.toString()).length > 9) return "overflow"
  let n:any = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  var str = ""
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || Number(b[n[1][0]] + a[n[1][1]])) + `${full ?" Crore":" Crore "}`
      : ""
  str +=
    n[2] != 0 ? " " + (a[Number(n[2])] || b[n[2][0]] + a[n[2][1]]) + " Lacs " : ""
  str +=
    n[3] != 0
      ? val
      : ""
  str += (n[4] != 0) ? val + ' ' : '';
  str += (n[5] != 0) ? val + ' ' : '';
  return `₹ ${str}`
}

// str +=
//     n[3] != 0
//       ? " " +  (a[Number(n[3])] || b[n[3][0]] + a[n[3][1]]) + " Thousands "
//       : ""
//   //str += (n[4] != 0) ? (a[Number(n[4])] || Number(b[n[4][0]]) + Number(a[n[4][1]])) + ' hundred ' : '';
//   //str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || parseInt(b[n[5][0]]+ Number(a[n[5][1]]))) + ' ' : '';

export function hasKeys(obj){
  if(obj){
    let keys = Object.keys(obj)
  
    // console.log(keys);
    
    if(keys.length > 0){
      return true
    }
    return false
  }
}

export function isArray(arr){
  return Array.isArray(arr)

}

export function isString(value){
  return typeof value  ==  'string' 
}

export function newLineSeparator(line:string,seperator?:string){

  
  // console.log("newLineSeparator",line);
  
  if(line && typeof line == 'string' ){
    if(!seperator){
      seperator = "|"
    }
    
    line?.replace(/\?/g,"")
    return line?.replace(/\|/g,"<br/>")
  }
  return line
}

export function arrayToCommaSeparated(line:string[]){
  
  return isArray(line)?  line?.join(", "): line
}

export function replaceNullUndefinedWithEmptyString(val){
  // console.log(val);
  
  return [null,undefined].includes(val) ? '': val;
}