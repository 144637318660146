<ng-container>
    <ng-container *ngIf="isArray(data)">
        <div *ngFor="let item of data" class="row data">
            <ng-container *ngFor="let el of item | keyvalue">
                <ng-container *ngIf="!isString(el.value)">
                    <div class="row data col-lg-12">
                        <div class="heading ml-3" style="width: 100%;"> {{mapping[el.key]}}</div>
                        <ng-container *ngFor="let inner of el.value | keyvalue">
                          <div class="data row">
                            <div class="input">
                                <div class="data__field">
                                  {{mapping[inner.key]}}
                                </div>
                            <div class="data__value">
                              {{inner.value}}
                            </div>
                          </div>
                        </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="isString(el.value)">
                <div class="data row">
                    <div class="input">
                        <div class="data__field">
                          {{mapping[el.key]}}
                        </div>
                    <div class="data__value">
                      {{el.value}}
                    </div>
                  </div>
                </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="!isArray(data)">
        <div class="row data">
            <ng-container *ngFor="let item of data | keyvalue">
                <ng-container *ngIf="!isString(item.value)">
                    <div class="row data col-lg-12">
                        T3
                        <div class="heading ml-3" style="width: 100%;"> {{mapping[item.key]}}</div>
                        <ng-container *ngFor="let inner of item.value | keyvalue">
                            <div class="col-lg-3 mb-5">
                                <div class="data__field">{{ mapping[inner.key] }}</div>
                            </div>
                            <div class="col-lg-3">
                                <div class="data--row__value mat-elevation-z1">
                                    <span>
                                        {{ inner.value }}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="isString(item.value)">
                    <div class="data row">
                    <div class="input">
                        <div class="data__field">
                          {{mapping[item.key]}}
                        </div>
                    <div class="data__value">
                      {{item.value}}
                    </div>
                  </div>
                </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-container>