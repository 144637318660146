import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogInComponent } from './components/log-in/log-in.component';
import { MaterialImportsModule } from './modules/shared-modules/material-imports/material-imports.module';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [LogInComponent],
  imports: [
    CommonModule,
    MaterialImportsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule,
    RouterModule
  ],
  exports:[LogInComponent,MaterialImportsModule]
})
export class ThemeModule { }
