import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SubmitFormsService } from '../../services/submit-forms.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogInComponent implements OnInit {
  loginForm: FormGroup;
  showFormPassword = false;
  submitted = false;

  constructor(private fb: FormBuilder, 
    private submitService: SubmitFormsService,
    private _authenticationService:AuthenticationService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      showPassword:[''],
      rememberMe: [''],
    });
  }

  ngOnInit() {
    this.subscribeOnFormChanges(this.loginForm);

    this.loginForm.get('showPassword').valueChanges.subscribe(val => {
      this.showFormPassword = val;
    });
  }

  subscribeOnFormChanges(formName): void {
    formName.valueChanges.subscribe(val => {
      console.log(val);
    });
  }

  onSubmit() {
    localStorage.setItem("username", this.loginForm.value.email);
    console.log(this.loginForm.value.email, 456);
    
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true;
    this._authenticationService
      .login({})
      .pipe()
      .subscribe({
        next: (res) => {},
      });
  }
}
