<div *ngIf="isLoginPage" class="bg1"></div>
<header [ngClass]="{ isLoggedIn: !isLoginPage }">
  <ng-container *ngIf="isLoginPage">
    <!-- <div class="logo" style="padding-left: 3rem">
      <img src="assets/images/logo.png" alt="Lumiq" />
    </div> -->
  </ng-container>
  <ng-container *ngIf="!isLoginPage">
    <div class="logo">
      <!-- <app-em360></app-em360> -->
      <img src="assets/images/logo.png" alt="Lumiq" />

    </div>
    <nav class="navigation-bar">
      <ul>
        <li class="navigation-item">
          <a class="navigation-link" (click)="logout()">
            <span class="navigation-text">
              <!-- Logout -->
              <i class="fas fa-sign-out-alt" style="cursor: pointer;" ></i> 
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </ng-container>
</header>
