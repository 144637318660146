import { Component, Input, OnInit } from '@angular/core';
import { isArray, isString } from '../../functions';
import mapping from '../../data/mapping.json';

@Component({
  selector: 'app-data-iterator',
  templateUrl: './data-iterator.component.html',
  styleUrls: ['./data-iterator.component.scss']
})
export class DataIteratorComponent implements OnInit {

  mapping = mapping
  isArray =isArray
  isString = isString
  @Input() data:{
    [key:string]:string
  }[] = []

  constructor() { }

  ngOnInit(): void { 
    
    console.log(mapping);
    
   }

}
