import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {Router} from '@angular/router'; // import router from angular router


@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.scss'],
})
export class Header2Component implements OnInit {
  constructor(
    private _location: Location,
    private _authenticationService: AuthenticationService,
    private route:Router
  ) {}

  ngOnInit() {}

  get isLoginPage() {
    return this._location.path() == '/auth/login';
  }

  logout() {
    // this._authenticationService.logout();
    this.route.navigate(['/auth/login']);
  }
}
