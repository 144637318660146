import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Header2Component } from './header2/header2.component';
import { MaterialModule } from 'src/app/material/material.module';
import { Em360Component } from './em360/em360.component';
import { TabComponent } from './tab/tab.component';
import { FooterComponent } from './footer/footer.component';
import { TableComponent } from './table/table.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DataIteratorComponent } from './data-iterator/data-iterator.component';

@NgModule({
  declarations: [
    Header2Component,
    Em360Component,
    TabComponent,
    FooterComponent,
    TableComponent,
    PaginationComponent,
    DataIteratorComponent,
  ],
  imports: [CommonModule, MaterialModule],
  exports: [Header2Component, Em360Component, 
    TabComponent, FooterComponent, 
    PaginationComponent,DataIteratorComponent],
})
export class PageComponentsModule {}
