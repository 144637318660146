// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: '',
  openGalleryTabUrl: 'http://10.221.42.177:4016/dataentry-qc/viewAllDocuments/', 
  filenetApiUrl: 'http://10.221.41.88:8140/empower/ingenium/filenet/list/v1/',
  filenetImageDownloadUrl:'http://10.221.41.88:8140/empower/ingenium/filenet/content/v1/',
  supportDocImage:'http://10.221.42.177:4017/support_doc/image_links/',
  empowerFindByAppNumber:'http://10.221.41.88:6011/empower/app-details/findbyappnumber/v1/',
  supportDocUpdate:'http://10.221.42.177:4017/support_doc/document_update/',
  geniusApplication: 'http://10.221.42.177:4010/genius/applicationdetails/',
  proposalFormUrl: 'http://10.221.41.88:6011/empower/test/images/',
  getLookUp: 'http://10.221.42.177:4016/dataentry-qc/api/getlookup',
  searchCustomerListing : "https://asia-south1-rnd2022.cloudfunctions.net/GCF-4",
  getCustomer: "https://asia-south1-rnd2022.cloudfunctions.net/getBankCustomer",
  getInsuranceCustomer : "https://asia-south1-rnd2022.cloudfunctions.net/getInsuranceCustomer",
  getPcInsurance : "https://asia-south1-rnd2022.cloudfunctions.net/getGICustomer"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
